import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, analytics } from "../../FirebaseData";
import { Button, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import MyStrings from "../../MyStrings.js";
import TransparentButton from "../TransparentButton";
import { USER_NOT_FOUND, WRONG_PASSWORD } from "../../AuthErrorCodes";
import AuthBaseLayout from "./AuthBaseLayout";

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, MyStrings.Errors.passwordTooShort)
    .required(MyStrings.Errors.fieldRequired),
});

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [oobCode, setoobCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    var url = new URL(window.location.href);
    var code = url.searchParams.get("oobCode");
    console.log(code);
    auth
      .verifyPasswordResetCode(code)
      .then(function (email) {
        setEmail(email);
        setoobCode(code);
      })
      .catch(function () {
        // Invalid code

        let msg = "Invalid Or Expired Link";
        setError(msg);
      });
  }, []);

  const reset = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(password);
    if (!password) {
      setError("Please Enter A Valid Password");
      setLoading(false);
    } else {
      auth
        .confirmPasswordReset(oobCode, password)
        .then(function () {
          // Success
          setError(null);

          setSuccess("Success! You Can Now Login With Your New Password");
          setLoading(false);
        })
        .catch((err) => {
          // Invalid code
          setError(err.message);
          setLoading(false);
        });
    }
  };

  if (loading) {
    return (
      <AuthBaseLayout>
        <Row>
          <Col>
            <h1 style={{ textAlign: "center" }}>Loading...</h1>
          </Col>
        </Row>
      </AuthBaseLayout>
    );
  } else {
    return (
      <AuthBaseLayout>
        <Row>
          <Col>
            {success ? (
              <Alert variant="success">{success}</Alert>
            ) : (
              <Formik
                initialValues={{ password: "" }}
                validationSchema={ResetPasswordSchema}
                onSubmit={reset}
              >
                <Form noValidate={true} onSubmit={reset}>
                  <Form.Group controlId="passwordForm">
                    <Form.Label>Enter New Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder={MyStrings.passwordPlaceholder}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  {error && <Alert variant="danger">{error}</Alert>}

                  {loading ? (
                    <div className="p-2 text-center">
                      <Spinner
                        className="p-2"
                        animation="border"
                        variant="info"
                      />
                    </div>
                  ) : (
                    <Button className="p-2 w-100" type="submit" variant="info">
                      Submit
                    </Button>
                  )}
                </Form>
              </Formik>
            )}
          </Col>
        </Row>
      </AuthBaseLayout>
    );
  }
};

export default ResetPassword;
