const firebase = window.firebase;

const firebaseConfig = {
  apiKey: "AIzaSyCgjkqNTKXCamOp0VyJGw6hgVzJXlyqc7I",
  authDomain: "karlekstanken-copy-3.firebaseapp.com",
  databaseURL: "https://karlekstanken-copy-3-default-rtdb.firebaseio.com",
  projectId: "karlekstanken-copy-3",
  storageBucket: "karlekstanken-copy-3.appspot.com",
  messagingSenderId: "665405390311",
  appId: "1:665405390311:web:bb783a009c067864965142",
  measurementId: "G-MLVGXF7W35",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
//TODO: disable when testing, enable when publishing
analytics.setAnalyticsCollectionEnabled(true);

const db = app.firestore();
db.enablePersistence()
  .then(() => {
    console.log("firestore offline persistence enabled");
  })
  .catch((e) => console.log(e));

const auth = app.auth();
const functions = app.functions("europe-west1");
// Executes cloud functions from locally running functions emulator
//functions.useFunctionsEmulator("http://localhost:5001");
const EmailAuthProvider = firebase.auth.EmailAuthProvider;
const ServerTimestamp = firebase.firestore.Timestamp;
const FieldValue = firebase.firestore.FieldValue;
export {
  app,
  analytics,
  db,
  auth,
  functions,
  EmailAuthProvider,
  ServerTimestamp,
  FieldValue,
};
